<template>
<div class="flex-center">
  <div class="content full-height flex-center">
    <h1>Socials</h1>
    <div class="social-icons">
      <a href="https://github.com/mhartshorn" target="_blank" rel="noopener">
        <GithubLogo />
      </a>
      <a href="https://gitlab.com/matt-hartshorn" target="_blank" rel="noopener">
        <GitlabLogo />
      </a>
      <a href="https://www.linkedin.com/in/matt-hartshorn/" target="_blank" rel="noopener">
        <LinkedinLogo />
      </a>
      <a href="mailto:dev@matthartshorn.com">
        <EmailLogo />
      </a>
    </div>
  </div>
</div>
</template>

<script>
  import GithubLogo from '@/assets/github.svg';
  import GitlabLogo from '@/assets/gitlab.svg';
  import LinkedinLogo from '@/assets/linkedin.svg';
  import EmailLogo from '@/assets/email.svg';
  
  export default {
    components: {
      GithubLogo, GitlabLogo, LinkedinLogo, EmailLogo
    },
    created () {
      this.$emit('positionDot', {'top': 'auto', 'right': '110px', 'bottom': '30px', 'left': 'auto'})
    },
    beforeRouteLeave(to, from, next) {
      this.$emit('addAnimation', ['animate-dot__up'])
      setTimeout(() => {
        next()
      }, 500);
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        if(from.name != null){
          vm.$emit('addAnimation', ['animate-dot__down'])
          setTimeout(() => {
            vm.$emit('removeAnimation', ['animate-dot__up','animate-dot__down'])
          }, 1000);
        }
      })
    }
  }
</script>

<style scoped lang="scss">
  .social-icons {
    display: flex;
    align-items: center;
    margin-top: 5vh;
    svg {
      &:hover {
        path {
          fill: transparent;
          stroke: $light;
          stroke-width: 15px;
        }
      }
    }
    svg {
      width: 30px;
      margin-right: 25px;
      path {
        fill: $light;
      }
    }
  }
  
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    .social-icons {
      svg {
        margin-right: 50px;
        width: 50px;
      }
    }
  }
</style>